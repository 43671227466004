import React, { useState, useEffect } from "react";
import api from "../../../../../../core/services/api";
import { useStyles } from "./styles";

//custom components
import GraphCard from "./GraphCard";

//material ui
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import LinearProgress from '@material-ui/core/LinearProgress'

function TimeDock(props) {
  const classes = useStyles();
  const [lod, setLod] = useState({ loading: true })
  const [state, setState] = useState({
    series: [
      {
        name: "Tempo Médio (Minutos)",
        type: "column",
        data: [],
      },
      {
        name: "Target (Minutos)",
        type: "line",
        data: [],
      },
    ],
    legends: [],
    filter_id: null,
    filter: [
      { id: 1, name: "Supplier Park" },
      { id: 2, name: "Locais Jeep" },
    ],
  });

  useEffect(() => {
    getData();
  }, [props.start_day, props.end_day, state.filter_id, props.routes]);

  async function getData() {
    try {
      setLod((prev) => ({
        ...prev,
        loading: true
      }))

      const data = await api.get(
        `/sla-report-geral-time-dock?start_at=${props.start_day}&end_at=${props.end_day}&routes=[${props.routes}]`
      );
      let time_dock = data.data.data.data || [];
      if (state.filter_id != null) {
        if (state.filter_id == 1) {
          time_dock = time_dock.filter((item) => {
            return (
              item.place === "ADLER" ||
              item.place === "BROSE" ||
              item.place === "CMA - BODY SHOP EXP" ||
              item.place === "CMA - BODY SHOP REC" ||
              item.place == "CMA - PRESS SHOP" ||
              item.place == "CMA – TANQUE" ||
              item.place == "DENSO EXP" ||
              item.place == "DENSO REC" ||
              item.place == "FAURECIA EXP" ||
              item.place == "FAURECIA REC CT" ||
              item.place == "FAURECIA REC DENSO" ||
              item.place == "LEAR" ||
              item.place == "PIRELLI" ||
              item.place == "PMC EXP FUN" ||
              item.place == "PMC EXP LEAR" ||
              item.place == "PMC REC" ||
              item.place == "PRIMASOLE" ||
              item.place == "REVESTCOAT" ||
              item.place == "SP3 - MARELLI REC" ||
              item.place == "SP4 - MARELLI EXP" ||
              item.place == "SP4 - MARELLI REC" ||
              item.place == "TIBERINA EXP FUN" ||
              item.place == "TIBERINA EXP SP" ||
              item.place == "TIBERINA REC"
            );
          });
        } else {
          time_dock = time_dock.filter((item) => {
            return (
              item.place == "30 - FUNILARIA" ||
              item.place == "31 - FUNILARIA" ||
              item.place == "55 - MONTAGEM" ||
              item.place == "56 - MONTAGEM" ||
              item.place == "57 - MONTAGEM" ||
              item.place == "60 - MONTAGEM" ||
              item.place == "62 - MONTAGEM" ||
              item.place == "CDC - INT" ||
              item.place == "CDC - MEC" ||
              item.place == "CDC GA" ||
              item.place == "CDC - SP" ||
              item.place == "CSC" ||
              item.place == "ILHA ECOLÓGICA" ||
              item.place == "PINTURA" ||
              item.place == "PRENSAS"
            );
          });
        }
      }

      time_dock = time_dock.sort((a, b) => (a.place > b.place) ? 1 : ((b.place > a.place) ? -1 : 0))
      let legends = [];
      let series = [
        {
          name: "Tempo Médio",
          type: "column",
          data: [],
        },
        {
          name: "Target",
          type: "line",
          data: [],
        },
      ];

      //CRIANDO LEGENDS
      time_dock.forEach((item, index) => {
        let exist = legends.includes(item.place);
        if (!exist) legends.push(item.place);
      });
      legends.forEach((item, index) => {
        series[0].data.push(0);
        series[1].data.push(0);
      });
      //CRIANDO SÉRIES
      time_dock.forEach((item, index) => {
        let idx = legends.indexOf(item.place);
        series[0].data[idx] = parseFloat(item.time);
        series[1].data[idx] = parseFloat(item.target);
      });



      setState((prev) => ({
        ...prev,
        series: series,
        legends: legends,
      }));
    } catch (error) {
      console.warn(error);
    } finally {
      setLod((prev) => ({
        ...prev,
        loading: false
      }))
    }
  }

  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography color="textSecondary" gutterBottom>
              Tempo de Doca
            </Typography>
          </Grid>
          <Grid item lg={2} sm={2} md={2} xs={2}>
            <Select
              MenuProps={{ disableScrollLock: true }}
              fullWidth
              variant="outlined"
              displayEmpty
              value={state.filter_id}
              onChange={(e) =>
                setState((prev) => ({
                  ...prev,
                  filter_id: e.target.value,
                }))
              }
            >
              <MenuItem key="all" value={null}>
                Todos os Locais
              </MenuItem>
              {state.filter.map((item) => {
                return (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
        </Grid>

        <GraphCard legends={state.legends} series={state.series} />
      </CardContent>
      {lod.loading &&
        <LinearProgress />
      }
      <CardActions></CardActions>
    </Card>
  );
}

export default TimeDock;
