import React, { useState, useEffect } from "react";
import api from "../../../../../../core/services/api";
import { useStyles } from "./styles";

//custom components
import GraphCard from "./GraphCard";

//material ui
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import LinearProgress from '@material-ui/core/LinearProgress';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { Grid } from "@material-ui/core";

import CustomSelect from "../../../../components/CustomSelect";

function TimeTotal(props) {

  const classes = useStyles();
  const [lod, setLod] = useState({ loading: true })
  const [state, setState] = useState({
    series: [],
    legends: [],
    local_list: [],
    type: [{ id: 1, name: "Ocorrência" }, { id: 2, name: "Justificativa" }],
    type_id: 0,
    justifications: [],
    justifications_id: 0,
    occurrences: [],
    occurrences_id: 0,
    filterOccurrences: [],
    filterOccurrences_id: 0,
    occurrenceChild_id: 0,
    searche: 0
  });
  useEffect(() => {
    getData();
  }, [props.start_day, props.end_day, props.routes, state.type_id, state.justifications_id, state.occurrences_id, state.filterOccurrences_id, state.searche]);

  async function getData() {
    try {
      setLod((prev) => ({
        ...prev,
        loading: true
      }))

      const occurrences = await api.get(`occurrence`);
      const justifications = await api.get(`justification?type=dock_time`);

      const data = await api.get(
        `sla-otd-local?start_at=${props.start_day}&end_at=${props.end_day}&routes=[${props.routes}]&type_id=${state.type_id}&justifications_id=${state.justifications_id}&occurrences_id=${state.occurrences_id}&filterOccurrences_id=${state.filterOccurrences_id}`
      );
      let otd = data.data.data.data[0] || [];

      let legends = [];
      let series = [];
      otd.forEach((item, index) => {
        legends.push(item.place);
        series.push(item.avg);
      });

      setState((prev) => ({
        ...prev,
        series: series,
        legends: legends,
        justifications: justifications.data.data,
        occurrences: occurrences.data
      }));
    } catch (error) {
      console.warn(error);
    }
    finally {
      setLod((prev) => ({
        ...prev,
        loading: false
      }))
    }
  }

  const handleChange = (e) => {

    let id = e.target.value;
    if (id == 0) {
      setState((prev) => ({
        ...prev,
        occurrences_id: 0,
        filterOccurrences_id: 0,
        justifications_id: 0,
        type_id: e.target.value,
        searche: id
      }))
    }
    else {
      setState((prev) => ({
        ...prev,
        type_id: e.target.value,
      }))
    }
  }

  const handleChangeJustification = (e) => {



    setState((prev) => ({
      ...prev,
      justifications_id: e.target.value,
      occurrences_id: 0,
      filterOccurrences_id: 0
    }))

  }

  const handleChangeOccurrences = (e) => {

    let id = e.target.value;
    if (id > 0) {
      let fo = state.occurrences.filter((o) => o.id == id)[0]
      setState((prev) => ({
        ...prev,
        occurrences_id: id,
        filterOccurrences: fo.occurrence
      }))
    }
    else {
      setState((prev) => ({
        ...prev,
        occurrences_id: id
      }))
    }

  }

  const handleChangeOccurrenceChild = (e) => {
    let id = e.target.value;
    setState((prev) => ({
      ...prev,
      filterOccurrences_id: id,
      justifications_id: 0
    }))
  }

  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid container justify="center" alignItems="center">

          <Grid item lg={3} sm={3} md={3} xs={3}>
            <Typography color="textSecondary" gutterBottom>
              Tempo Total / Período por Local
            </Typography>
          </Grid>

          <Grid item lg={9} sm={9} md={9} xs={9}>
            <Grid container justify="flex-end" spacing={1}>

              <Grid item lg={2} sm={2} md={2} xs={2}>
                <Select
                  MenuProps={{ disableScrollLock: true }}
                  fullWidth
                  variant="outlined"
                  displayEmpty
                  value={state.type_id}
                  onChange={(e) => handleChange(e)}
                >
                  <MenuItem key="all" value={0}>
                    Ocorrências e Justificativas
                  </MenuItem>
                  {state.type.map((item) => {
                    return (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>

              </Grid>

              {state.type_id == 1 &&
                <Grid item lg={2} sm={2} md={2} xs={2} >
                  <Select
                    MenuProps={{ disableScrollLock: true }}
                    fullWidth
                    variant="outlined"
                    displayEmpty
                    value={state.occurrences_id}
                    onChange={(e) => handleChangeOccurrences(e)}
                  >
                    <MenuItem key="all" value={0}>
                      Todas Ocorrências
                    </MenuItem>
                    {state.occurrences.map((item) => {
                      return (
                        <MenuItem key={item.id} value={item.id}>
                          {item.description}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Grid>
              }
              {state.type_id == 1 && state.occurrences_id &&
                <Grid item lg={2} sm={2} md={2} xs={2}>
                  <Select
                    MenuProps={{ disableScrollLock: true }}
                    fullWidth
                    variant="outlined"
                    displayEmpty
                    value={state.filterOccurrences_id}
                    onChange={(e) => handleChangeOccurrenceChild(e)}
                  >
                    <MenuItem key="all" value={0}>
                      Todos
                    </MenuItem>
                    {state.filterOccurrences.map((item) => {
                      return (
                        <MenuItem key={item.id} value={item.id}>
                          {item.description}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Grid>
              }

              {state.type_id == 2 &&
                <Grid item lg={2} sm={2} md={2} xs={2}>
                  <Select
                    MenuProps={{ disableScrollLock: true }}
                    fullWidth
                    variant="outlined"
                    displayEmpty
                    value={state.justifications_id}
                    onChange={(e) => handleChangeJustification(e)}
                  >
                    <MenuItem key="all" value={0}>
                      Todas Justificativas
                    </MenuItem>
                    {state.justifications.map((item) => {
                      return (
                        <MenuItem key={item.id} value={item.id}>
                          {item.description}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Grid>
              }


            </Grid>

          </Grid>


        </Grid>

        <GraphCard series={state.series} legends={state.legends} />
      </CardContent>
      {lod.loading &&
        <LinearProgress />
      }
    </Card>
  );
}

export default TimeTotal;

