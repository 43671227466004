import React, { useState } from "react";
import { useStyles } from "./styles";

//custom components
import GraphCard from "./GraphCard";

//material ui
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import LinearProgress from '@material-ui/core/LinearProgress'
//import state from "sweetalert/typings/modules/state";

function Effectiveness(props) {

  const [state, setState] = useState({ loading: true })

  function handleCallback(childData) {
    setState({ loading: childData })
  }

  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography color="textSecondary" gutterBottom>
          Efetividade das Janelas
        </Typography>
        <GraphCard
          start_day={props.start_day}
          end_day={props.end_day}
          routes={props.routes}
          parentCallback={handleCallback}
        />
      </CardContent>
      {state.loading &&
        <LinearProgress />
      }

    </Card>
  );
}

export default Effectiveness;
