import React, { useState, useEffect } from "react";
import api from "../../../../../core/services/api";
//@material-ui
import Grid from "@material-ui/core/Grid";


//custom components
import moment from "moment";
import OportunityEffectivenessRoute from "./OportunityEffectivenessRoute";
import LossTotalPercent from "./LossTotalPercent";
import TimeTotal from "./TimeTotal";

function OTDPanel(props) {
  return (
    <Grid container spacing={3}>
      <Grid item md={12} lg={12} sm={12} xs={12}>
        <OportunityEffectivenessRoute start_day={props.start_day} end_day={props.end_day} routes={props.routes} />
      </Grid>
      <Grid item md={12} lg={12} sm={12} xs={12}>
        <LossTotalPercent start_day={props.start_day} end_day={props.end_day} routes={props.routes} />
      </Grid>
      <Grid item md={12} lg={12} sm={12} xs={12}>
        <TimeTotal start_day={props.start_day} end_day={props.end_day} routes={props.routes} />
      </Grid>
    </Grid>
  );
}

export default OTDPanel;
