import React, { useState, useEffect } from "react";
import api from "../../../../../../core/services/api";
import { useStyles } from "./styles";

//custom components
import GraphCard from "./GraphCard";

//material ui
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import LinearProgress from '@material-ui/core/LinearProgress';

function LossTotalPercent(props) {
  const classes = useStyles();
  const [lod, setLod] = useState({ loading: true })
  const [state, setState] = useState({
    series: [
      {
        data: [],
      },
    ],
    legends: [],
  });
  useEffect(() => {
    getData();
  }, [props.start_day, props.end_day, props.routes]);

  async function getData() {

    try {

      setLod((prev) => ({
        ...prev,
        loading: true
      }))

      const data = await api.get(
        `sla-otd-type?start_at=${props.start_day}&end_at=${props.end_day}&routes=[${props.routes}]`
      );

      let saturation = data.data.data.data[0] || [];
      let legends = [];
      let series = [
        {
          name: "Perdas Total por Tipo",
          data: [],
        },
      ];

      saturation.forEach((item, index) => {
        console.log('item', item)
        legends.push(item.name);
        series[0].data.push(item.percent);
      });

      setState((prev) => ({
        ...prev,
        series: series,
        legends: legends,
      }));
    } catch (error) {
      console.warn(error);
    }
    finally {
      setLod((prev) => ({
        ...prev,
        loading: false
      }))
    }
  }

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography color="textSecondary" gutterBottom>
          % Perdas Total por Tipo
        </Typography>
        <GraphCard series={state.series} legends={state.legends} />
      </CardContent>
      {lod.loading &&
        <LinearProgress />
      }
    </Card>
  );
}

export default LossTotalPercent;
